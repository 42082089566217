import { setDatadogRumContext } from "setup_helper"
import { modalHandler, shareButton, initializeFacebook } from "sharing_helper"
import { importmapSetup } from "setup"

importmapSetup()

document.addEventListener("DOMContentLoaded", function() {
  setDatadogRumContext("key-page", "story-show")

  document.querySelectorAll("[data-modal-id]").forEach(el => {
    modalHandler(el.dataset.modalId, el.dataset.modalOpenSelector)
  })

  initializeFacebook()

  shareButton()
}, { once: true })
